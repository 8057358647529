import React, { useEffect } from "react";
import Aos from "aos";
import HomeDemo4Container from "./containers/HomeDemo4";

import "aos/dist/aos.css";
import "./assets/css/General.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/responsive.css";

import "bootstrap/dist/js/bootstrap.bundle.min";

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return <HomeDemo4Container />;
};

export default App;
