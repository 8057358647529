import {
  HomeDemo4FeaturesS1,
  HomeDemo4FeaturesS2,
  HomeDemo4FeaturesS3,
  HomeDemo4FeaturesS4,
  HomeDemo4FeaturesF1,
  HomeDemo4FeaturesF2,
  HomeDemo4FeaturesF3,
  HomeDemo4FeaturesF4,
  HomeDemo4FeaturesF5,
  HomeDemo4FeaturesF6,
  HomeDemo4TeamImg1,
  HomeDemo4TeamImg2,
  HomeDemo4TeamImg3,
  HomeDemo4BlogImg1,
  HomeDemo4BlogImg2,
  HomeDemo4BlogImg3,
  HomeDemo4Feature1,
  HomeDemo4Feature2,
  HomeDemo4Feature3,
  HomeDemo4Feature4,
  HomeDemo4Feature5,
  HomeDemo4Feature6,
  HomeDemo4Feature7,
  HomeDemo4Feature8,
} from "../../../utils/allImgs";

export const TokenFeaturesTop = [
  {
    img: HomeDemo4FeaturesF1,
    title: "Delivery Reports",
  },
  {
    img: HomeDemo4FeaturesF2,
    title: "Branded Sender ID",
  },
];

export const TokenFeaturesMed = [
  {
    img: HomeDemo4FeaturesF3,
    title: "Marketing Campigns",
  },
  {
    img: HomeDemo4FeaturesF4,
    title: "professional Routing",
  },
];

export const Services = [
  {
    img: HomeDemo4Feature1,
    title: "Instant settlement",
  },
  {
    img: HomeDemo4Feature2,
    title: "Flexibility",
  },
  {
    img: HomeDemo4Feature3,
    title: "Blockchain technology",
  },
  {
    img: HomeDemo4Feature4,
    title: "Experienced team",
  },
  {
    img: HomeDemo4Feature5,
    title: "Connect free",
  },
  {
    img: HomeDemo4Feature6,
    title: "AI matching",
  },
  {
    img: HomeDemo4Feature7,
    title: "Low cost",
  },
  {
    img: HomeDemo4Feature8,
    title: "Digital personas",
  },
];

export const TokenFeaturesDown = [
  {
    img: HomeDemo4FeaturesF5,
    title: "Traking API",
  },
  {
    img: HomeDemo4FeaturesF6,
    title: "Two-Way Messaging",
  },
];

export const SmartContractinfo = [
  {
    img: HomeDemo4FeaturesS1,
    title: "Cycles and schedules",
  },
  {
    img: HomeDemo4FeaturesS2,
    title: "Advanced application interface (API)",
  },
  {
    img: HomeDemo4FeaturesS3,
    title: "Software development kit (SDK)",
  },
  {
    img: HomeDemo4FeaturesS4,
    title: "Java virtual machine (JVM)",
  },
];

export const OurTeamInfo = [
  {
    img: HomeDemo4TeamImg1,
    title: "Rich Hurley",
    text: "Chief Executive Officer",
    linkedin: "https://www.linkedin.com/in/rich-hurley-156b005a/",
  },
  {
    img: HomeDemo4TeamImg2,
    title: "Josh Harrison",
    text: "Chief Technical Officer",
    linkedin: "https://www.linkedin.com/in/joshuaharrison13/",
  },
  {
    img: HomeDemo4TeamImg3,
    title: "Randy Wilson",
    text: "Chief Financial Officer",
    linkedin: "https://www.linkedin.com/in/randy-wilson-8437b64/",
  },
];

export const OurBlogInfo = [
  {
    img: HomeDemo4BlogImg1,
    title: "What is this Token for?.",
  },
  {
    img: HomeDemo4BlogImg2,
    title: "The most powerful Token",
  },
  {
    img: HomeDemo4BlogImg3,
    title: "How to get trial version",
  },
];
