/* eslint-disable import/no-anonymous-default-export */
const checkKeplrInstalled = () => {
  if (!window.keplr) {
    return false;
  } else {
    return true;
  }
};

const suggestChain = async (chainConfig) => {
  await window.keplr.experimentalSuggestChain(chainConfig);
};

const connectKeplr = async (chainConfig) => {
  if (checkKeplrInstalled()) {
    suggestChain(chainConfig);
  } else {
    alert("Please install keplr extension");
  }
};

export default connectKeplr;
