import { PieChart } from "react-minimal-pie-chart";

import SectionHeading from "../SectionHeading";

const TokenDistribution = ({ ClassSpanTitle }) => {
  return (
    <section className="token-distribution section-padding-100-85">
      <div className="container">
        <SectionHeading
          title="Tokenomics"
          text="Our Token Distribution"
          ClassSpanTitle={ClassSpanTitle}
        />

        <div className="row align-items-center">
          <div className="col-lg-3 col-sm-12">
            <h2
              className="text-center mb-30"
              data-aos="fade-up"
              data-aos-delay="0.3s"
            >
              Treasury
            </h2>
            <PieChart
              data={[{ value: 45, color: "#61e86a" }]}
              totalValue={100}
              lineWidth={20}
              label={({ dataEntry }) => dataEntry.value.toString().concat("%")}
              labelStyle={{
                fontSize: "25px",
                fontFamily: "sans-serif",
                fill: "#61e86a",
              }}
              labelPosition={0}
              startAngle={90}
            />
          </div>
          <div className="col-lg-3 col-sm-12">
            <h2
              className="text-center mb-30"
              data-aos="fade-up"
              data-aos-delay="0.3s"
            >
              IDO
            </h2>
            <PieChart
              data={[{ value: 20, color: "#55e0e0" }]}
              totalValue={100}
              lineWidth={20}
              label={({ dataEntry }) => dataEntry.value.toString().concat("%")}
              labelStyle={{
                fontSize: "25px",
                fontFamily: "sans-serif",
                fill: "#55e0e0",
              }}
              labelPosition={0}
              startAngle={15}
            />
          </div>
          <div className="col-lg-3 col-sm-12">
            <h2
              className="text-center mb-30"
              data-aos="fade-up"
              data-aos-delay="0.3s"
            >
              Airdrops
            </h2>
            <PieChart
              data={[{ value: 5, color: "#d055e0" }]}
              totalValue={100}
              lineWidth={20}
              label={({ dataEntry }) => dataEntry.value.toString().concat("%")}
              labelStyle={{
                fontSize: "25px",
                fontFamily: "sans-serif",
                fill: "#d055e0",
              }}
              labelPosition={0}
              startAngle={4}
            />
          </div>
          <div className="col-lg-3 col-sm-12">
            <h2
              className="text-center mb-30"
              data-aos="fade-up"
              data-aos-delay="0.3s"
            >
              Team
            </h2>
            <PieChart
              data={[{ value: 30, color: "#e0d955" }]}
              totalValue={100}
              lineWidth={20}
              label={({ dataEntry }) => dataEntry.value.toString().concat("%")}
              labelStyle={{
                fontSize: "25px",
                fontFamily: "sans-serif",
                fill: "#e0d955",
              }}
              labelPosition={0}
              startAngle={250}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TokenDistribution;
