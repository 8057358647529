const chainConfig = {
  features: ["no-legacy-stdTx"],
  chainId: "alteredcarbon",
  chainName: "Altered Carbon",
  rpc: "http://138.68.149.230:26657",
  rest: "http://138.68.149.230:1317",
  bip44: {
    coinType: 118,
  },
  bech32Config: {
    bech32PrefixAccAddr: "alteredcarbon",
    bech32PrefixAccPub: "alteredcarbon" + "pub",
    bech32PrefixValAddr: "alteredcarbon" + "valoper",
    bech32PrefixValPub: "alteredcarbon" + "valoperpub",
    bech32PrefixConsAddr: "alteredcarbon" + "valcons",
    bech32PrefixConsPub: "alteredcarbon" + "valconspub",
  },
  currencies: [
    {
      coinDenom: "alteredCarbon",
      coinMinimalDenom: "alteredCarbon",
      coinDecimals: 0,
      //   coinGeckoId: "cosmos",
    },
  ],
  feeCurrencies: [
    {
      coinDenom: "alteredCarbon",
      coinMinimalDenom: "alteredCarbon",
      coinDecimals: 0,
      //   coinGeckoId: "cosmos",
    },
  ],
  stakeCurrency: {
    coinDenom: "alteredCarbon",
    coinMinimalDenom: "alteredCarbon",
    coinDecimals: 0,
    coinGeckoId: "cosmos",
  },
  coinType: 118,
  gasPriceStep: {
    low: 0.0001,
    average: 0.00025,
    high: 0.0003,
  },
};

export default chainConfig;
