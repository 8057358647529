import IcoName from "../../data/data-layout/Footer/data-IcoName.json";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fab);

const Subscribe = () => {
  return (
    <section className="container mb-5" id="social">
      <div className="subscribe section-padding-0-0">
        <div className="row">
          <div className="col-sm-12">
            <div className="subscribe-wrapper">
              <div className="section-heading text-center">
                <h2 data-aos="fade-up" data-aos-delay="300">
                  Don’t Miss News And Updates!
                </h2>
              </div>
              {/* <div className="service-text text-center">
                  <div className="subscribe-section has-shadow">
                    <div className="input-wrapper">
                      <i className="fa fa-home" />
                      <input type="email" placeholder="Enter your Email" />
                    </div>
                    <button className="btn more-btn">Subscribe</button>
                  </div>
                </div> */}
              <div className="service-text text-center">
                <div
                  className="footer-social-info fadeInUp"
                  data-wow-delay="0.4s"
                >
                  {IcoName &&
                    IcoName.map((item, key) => (
                      <a key={key} href={item.href}>
                        <i className={item.IcoName} aria-hidden="true" />
                      </a>
                    ))}
                  <a href="https://discord.gg/BWZSK4PYuX">
                    <FontAwesomeIcon
                      className={"discord-info"}
                      aria-hidden="true"
                      icon={["fab", "discord"]}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
