import { HomeDemo1Computer } from "../../utils/allImgs";

const AboutOther = ({ ClassTitle = "gradient-text blue" }) => {
  return (
    <section
      className="about-us-area section-padding-0-100 clearfix"
      id="overview"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 offset-lg-0">
            <div className="who-we-contant">
              <div
                className="dream-dots text-left fadeInUp"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <span className={ClassTitle}>The case for innovation</span>
              </div>
              <h4 className="fadeInUp" data-aos="fade-up" data-aos-delay="200">
                Carbon Reducing Projects need frictionless market access
              </h4>
              <p className="fadeInUp" data-aos="fade-up" data-aos-delay="200">
                Traders and brokers can charge fees as high as 8% when
                transacting in carbon credits. This translates into less money
                for the projects doing the hard work to protect the planet and
                higher costs for people who want to offset their carbon
                footprint.
              </p>
              <p className="fadeInUp" data-aos="fade-up" data-aos-delay="300">
                The market remains mostly opaque. There is no easy way to price
                carbon credits from different project types to decide where to
                optimally direct funds based on your personal carbon reduction
                and investing goals.
              </p>
              {/* <a className="btn more-btn mt-30" href="#">Read More</a> */}
            </div>
          </div>
          <div className="col-12 col-lg-6 offset-lg-0 col-md-12 mt-30 no-padding-left">
            <div className="welcome-meter floating-anim fadeInUp">
              <img draggable="false" src={HomeDemo1Computer} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutOther;
