const SpreadMap = ({ Wwhitepaper, SectionIcon11 }) => {
  return (
    <section className="spread-map download">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-xs-12">
            <div className="welcome-meter">
              <img
                draggable="false"
                src={Wwhitepaper}
                className="center-block"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 col-xs-12 mt-s">
            <div className="who-we-contant">
              <div className="dream-dots text-left">
                <img draggable="false" src={SectionIcon11} alt="" />
              </div>
              <h4 className="text-white">Download Our Whitepaper</h4>
              <p className="text-white">
                Please do read our whitepaper which sets out full details of the
                problem as we see it and the ambitious journey we want to take.
              </p>
              <a
                className="btn dream-btn mt-30"
                href="Altered_Carbon_Whitepaper.pdf"
                download
              >
                Get Whitepaper
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpreadMap;
